export const getCheapestAvailabilityDeparture = (
  availability: Contentful.TAvailability | undefined | null
) => {
  if (
    !availability ||
    !availability.availabilityData ||
    availability.availabilityData.voyages.length < 1
  ) {
    return undefined;
  }

  const cheapestDeparture = availability.availabilityData.voyages.reduce(
    (prev, curr) => (prev.price < curr.price ? prev : curr)
  );

  return cheapestDeparture;
};

export const getMostExpensiveAvailabilityDeparture = (
  availability: Contentful.TAvailability | undefined | null
) => {
  if (
    !availability ||
    !availability.availabilityData ||
    availability.availabilityData.voyages.length < 1
  ) {
    return undefined;
  }

  const cheapestDeparture = availability.availabilityData.voyages.reduce(
    (prev, curr) => (prev.price < curr.price ? curr : prev)
  );

  return cheapestDeparture;
};

export const getCheapestDepartureItem = (
  availability:
    | { departures: { price: number; strikethroughPrice: number }[] }
    | undefined
    | null
) => {
  if (!availability || availability.departures.length < 1) {
    return { price: null, strikethroughPrice: null };
  }

  const cheapestDeparture = availability.departures.reduce((prev, curr) =>
    prev.price < curr.price ? prev : curr
  );

  return cheapestDeparture;
};
