import { getVoyageDetails } from '@utils/mappers/quote';

const getRequestedCabinPassengers = (
  cabinIndex: number,
  cabins: ViewModel.Cabin.TRootObject
) => cabins.partyMixDetails.cabinsRequested[cabinIndex].passengers;

export const getCabinFromQuote = (
  cabinIndex: number,
  quote: PG.Response.Quote.TRootObject,
  cabins: ViewModel.Cabin.TRootObject
) => {
  const voyageDetails = getVoyageDetails(quote);

  return (
    voyageDetails?.cabins.find((cabin) =>
      cabin.passengers.every((bookedPassenger) =>
        getRequestedCabinPassengers(cabinIndex, cabins).some(
          (requestedPassenger) =>
            requestedPassenger.passengerId === bookedPassenger.passengerId
        )
      )
    ) ?? null
  );
};

const getCabinId = (
  cabinIndex: number,
  quote: PG.Response.Quote.TRootObject,
  cabins: ViewModel.Cabin.TRootObject
) => getCabinFromQuote(cabinIndex, quote, cabins)?.cabinId ?? null;

const getCabinNumber = (
  cabinIndex: number,
  quote: PG.Response.Quote.TRootObject,
  cabins: ViewModel.Cabin.TRootObject
) => getCabinFromQuote(cabinIndex, quote, cabins)?.cabinNumber ?? null;

const getCabinGrade = (
  cabinIndex: number,
  quote: PG.Response.Quote.TRootObject,
  cabins: ViewModel.Cabin.TRootObject
) => getCabinFromQuote(cabinIndex, quote, cabins)?.cabinGrade ?? null;

export const mapToCabinRequest = (
  cabinIndex: number,
  selectedCabinGrade: string,
  quoteId: string,
  voyageId: string,
  cabins: ViewModel.Cabin.TRootObject,
  quote: PG.Response.Quote.TRootObject
): PG.Request.Cabin.TRootObject => {
  const requestBody: PG.Request.Cabin.TRootObject = {
    quoteId,
    voyageId,
    cabins: [
      {
        cabinId: getCabinId(cabinIndex, quote, cabins),
        cabinGrade: selectedCabinGrade,
        cabinNumber:
          selectedCabinGrade === getCabinGrade(cabinIndex, quote, cabins)
            ? getCabinNumber(cabinIndex, quote, cabins)?.toString() ?? null
            : null,
        passengers: getRequestedCabinPassengers(cabinIndex, cabins).map(
          (passenger) => ({
            passengerId: passenger.passengerId,
            hasBerth: passenger.hasBerth
          })
        )
      }
    ],
    deckspaces: null
  };
  return requestBody;
};

export const mapToCabinNumberRequest = (
  cabin: PG.Request.Cabin.TCabin,
  quoteId: string,
  voyageId: string
): PG.Request.Cabin.TRootObject => {
  const requestBody: PG.Request.Cabin.TRootObject = {
    quoteId,
    voyageId,
    cabins: [cabin],
    deckspaces: null
  };
  return requestBody;
};
