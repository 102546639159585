import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { TVoyageBookingFlowInformation } from '@content/requests/voyage/getVoyageBookingFlowInformationBySlug';
import { Voyage } from '@content/models';
import { retroFitAvailability } from '@content/models/voyage';
import { getCabinFromQuote } from '@components/booking/cabin/helpers/mappings';

import { getCheapestAvailabilityDeparture } from '../mappers/availability';
import { buildEcommerceItem } from './eventBuilder';
import {
  getArrivalDeparturePackages,
  getCabins,
  getDuration,
  getExcursions,
  getPackage,
  getVoyageDetails
} from '../mappers/quote';
import {
  getArrivalDepaturePackages,
  getPackageFromBooking,
  getPassengerGroup,
  getVoyageDetailsFromBooking
} from '../mappers/bookingSummary';
import getDateDifferenceInDays from '../getDateDifferenceInDays';
import createDateAsUTC from '../createDateAsUTC';

const regexes = [
  { regex: /^(?:.{2}GBR|.{4}GBR)/, result: 'British Isles' },
  { regex: /^(?:.{2}ANT|.{4}ANT)/, result: 'Antartica' },
  { regex: /^(?:.{2}GAL|.{4}GAL)/, result: 'Galapagos' },
  { regex: /^(?:.{2}EUR|.{4}EUR)/, result: 'Europe' },
  { regex: /^(?:.{2}ICE|.{4}ICE)/, result: 'Iceland' },
  { regex: /^(?:.{2}ALA|.{4}ALA)/, result: 'Alaska' },
  { regex: /^(?:.{2}GRE|.{4}GRE)/, result: 'Greenland' },
  { regex: /^(?:.{2}TRA|.{4}TRA)/, result: 'Trans Atalantic' },
  { regex: /^(?:.{2}WOR|.{4}WOR)/, result: 'World' },
  { regex: /^(?:.{2}NAM|.{4}NAM)/, result: 'North America' },
  { regex: /^(?:.{2}CAM|.{4}CAM)/, result: 'Central America' },
  { regex: /^(?:.{2}SOU|.{4}SOU)/, result: 'South America' },
  { regex: /^(?:.{2}NWP|.{4}NWP)/, result: 'NW Passage' },
  { regex: /^(?:.{2}SPI|.{4}SPI)/, result: 'Spitzbergen' },
  { regex: /^(?:.{2}NOR|.{4}NOR)|^(?:.{2}ICO|.{4}ICO)/, result: 'Norway' },
  { regex: /^(?:.{2}AFR|.{4}AFR)/, result: 'Africa' },
  { regex: /^(?:.{2}CIS|.{4}CIS)/, result: 'Canary Islands' }
];

export const getDestinationFromBookingCode = (
  bookingCode: string | undefined
) => {
  let matchResult = 'Unknown';
  if (!bookingCode) {
    return matchResult;
  }
  regexes.some(({ regex, result }) => {
    if (regex.test(bookingCode)) {
      matchResult = result;
      return true;
    }
    return false;
  });
  return matchResult;
};

/* ---------- App events not in the Booking flow ---------- */

export const returnItemFromCruiseCardInfoModel = (
  voyage: ViewModel.CruiseCard.TCruiseCardInfo
): Analytics.Events.TVoyageItem => {
  const cheapestDepartureFromContentful = getCheapestAvailabilityDeparture(
    voyage.availability
  );

  return buildEcommerceItem({
    id: voyage?.id ?? '', // id is SysId
    name: voyage?.titleInEnglish ?? '',
    itemCategory: 'Voyage',
    price: cheapestDepartureFromContentful?.price ?? 0,
    bookingCode: cheapestDepartureFromContentful?.packageCode,
    destination: getDestinationFromBookingCode(
      cheapestDepartureFromContentful?.packageCode
    ),
    departureDate: cheapestDepartureFromContentful?.date ?? '',
    daysUntilDeparture: cheapestDepartureFromContentful?.date
      ? differenceInCalendarDays(
          new Date(cheapestDepartureFromContentful.date),
          new Date()
        )
      : undefined,
    duration: voyage?.availability?.availabilityData?.duration,
    shipName: voyage?.ships?.[0]
  }) as Analytics.Events.TVoyageItem;
};

export const returnItemFromVoyage = (
  voyage: Voyage
): Analytics.Events.TVoyageItem => {
  const cheapestDepartureFromContentful = getCheapestAvailabilityDeparture(
    retroFitAvailability(voyage.availability)
  );

  return buildEcommerceItem({
    id: voyage.sysId,
    name: voyage?.titleInEnglish ?? '',
    itemCategory: 'Voyage',
    price: cheapestDepartureFromContentful?.price ?? 0,
    bookingCode: cheapestDepartureFromContentful?.packageCode,
    destination: getDestinationFromBookingCode(
      cheapestDepartureFromContentful?.packageCode
    ),
    departureDate: cheapestDepartureFromContentful?.date,
    daysUntilDeparture: cheapestDepartureFromContentful?.date
      ? differenceInCalendarDays(
          new Date(cheapestDepartureFromContentful.date),
          new Date()
        )
      : undefined,
    duration: voyage?.availability?.availabilityData?.duration,
    shipName: voyage?.ships?.[0].name
  }) as Analytics.Events.TVoyageItem;
};

/* ---------- Booking flow events ---------- */

type TItem = {
  price: number | null;
  quantity?: number;
};

/* 
Most of the time we are iterating through passenger info and returning an 
array of identical information. The only thing that is likely to be different
is the price as adult, child and infant travellers will have different prices.

Therefore, merge all identical items except on price AND increment the 
quantity...
*/
const mergeItemsExceptBy = <T extends TItem>(array: T[], key: keyof T) =>
  array.reduce((acc: T[], current) => {
    const existingItemIndex = acc.findIndex((i) => i[key] === current[key]);

    if (existingItemIndex !== -1 && acc[existingItemIndex]) {
      acc[existingItemIndex].quantity =
        (acc[existingItemIndex].quantity || 0) + 1;
    } else {
      acc.push(current);
    }
    return acc;
  }, []);

export const returnViewItemFromExcursion = ({
  excursion,
  englishName
}: {
  excursion: ViewModel.Activity.TEnrichedActivity;
  englishName: string | undefined;
}) =>
  buildEcommerceItem({
    id: excursion.bookingCode ?? '',
    name: englishName ?? excursion.name,
    itemCategory: 'Excursion',
    price: excursion.defaultPrice,
    bookingCode: excursion.bookingCode ?? '',
    included: excursion.bookingCode?.startsWith('INCL') ?? false
  });

export const returnViewItemFromFlight = ({
  arrDepPackage
}: {
  arrDepPackage: ViewModel.ArrivalDeparture.TPackage;
}) =>
  buildEcommerceItem({
    id: arrDepPackage.packageCode,
    name: arrDepPackage.description,
    itemCategory: 'Arr/Dep Package',
    itemCategory2: arrDepPackage.type.toUpperCase(),
    price: arrDepPackage.price,
    bookingCode: arrDepPackage.packageCode
  });

/* Uppercase arrDepPackage.type so that it aligns with the uppercase
pageType value that Booking/Quote uses */
export const returnFlightEvent = ({
  arrDepPackage
}: {
  arrDepPackage: ViewModel.ArrivalDeparture.TPackage;
}) => {
  const items = arrDepPackage.passengerIds.map(
    (currentPassenger) =>
      buildEcommerceItem({
        id: arrDepPackage.packageCode,
        name: arrDepPackage.description,
        itemCategory: 'Arr/Dep Package',
        itemCategory2: arrDepPackage.type.toUpperCase(),
        price:
          arrDepPackage.guestPrices.find(
            (g) => g.passengerId === currentPassenger
          )?.price ?? 0,
        quantity: 1,
        bookingCode: arrDepPackage.packageCode
      }) as Analytics.Events.TExtraItem
  );
  const mergedItems = mergeItemsExceptBy(items, 'price');
  return {
    totalPrice: mergedItems.reduce((acc, curr) => acc + (curr?.price ?? 0), 0),
    items: mergedItems
  };
};

export const returnExcursionEvent = (
  activity: ViewModel.Activity.TEnrichedActivity,
  englishName: string | undefined,
  passengers: DataModel.Booking.Excursion.Passenger[]
) => {
  const items = passengers.map(
    (passenger) =>
      buildEcommerceItem({
        id: activity.bookingCode ?? '',
        name: englishName ?? activity.name,
        itemCategory: 'Excursion',
        price:
          activity.passengers.find(
            (a) => a.passengerId === passenger.passengerId
          )?.price ?? 0,
        bookingCode: activity.bookingCode ?? '',
        quantity: 1,
        included: activity.bookingCode?.startsWith('INCL') ?? false
      }) as Analytics.Events.TExtraItem
  );
  const mergedItems = mergeItemsExceptBy(items, 'price');

  return {
    totalPrice: mergedItems.reduce((acc, curr) => acc + (curr?.price ?? 0), 0),
    items: mergedItems
  };
};

const returnCabins = ({
  cabin,
  voyage,
  duration,
  packageData,
  voyageDetails
}: {
  cabin: PG.Response.Shared.TCabin;
  voyage: TVoyageBookingFlowInformation;
  duration: number | undefined;
  packageData:
    | PG.Response.Quote.TPackage
    | PG.Response.Booking.TPackage
    | undefined;
  voyageDetails: PG.Response.Shared.TVoyageDetails | null | undefined;
}) => {
  let daysUntilDeparture: number | undefined = 0;
  if (voyageDetails) {
    daysUntilDeparture = differenceInCalendarDays(
      new Date(voyageDetails.departureDateTime),
      new Date()
    );
  }

  const items = cabin.passengers.map(
    (passenger) =>
      buildEcommerceItem({
        id: voyage.sysId,
        duration,
        itemCategory: 'Voyage',
        price: passenger.includedPrice,
        quantity: 1,
        bookingCode: packageData?.packageCode ?? '',
        destination: getDestinationFromBookingCode(packageData?.packageCode),
        itemVariant: cabin.genericCabinCategory,
        coupon: Array.isArray(passenger.promotions)
          ? passenger.promotions.join()
          : passenger.promotions,
        departureDate: voyageDetails?.departureDateTime.slice(0, 10),
        arrivalDate: voyageDetails?.arrivalDateTime.slice(0, 10),
        shipName: voyageDetails?.shipName,
        name: voyage?.titleInEnglish ?? '',
        daysUntilDeparture
      }) as Analytics.Events.TVoyageItem
  );
  return mergeItemsExceptBy(items, 'price');
};

/* Used by purchase events below */
const returnBookingAndQuoteEvents = (
  passengers: PG.Response.Shared.TPricePassenger[],
  arrDepPackage:
    | Pick<
        PG.Response.Quote.TPackage,
        'packageCode' | 'description' | 'packageType'
      >
    | Pick<
        PG.Response.Booking.TPackage,
        'packageCode' | 'description' | 'packageType'
      >
) =>
  passengers.map(
    (passenger) =>
      buildEcommerceItem({
        id: arrDepPackage.packageCode,
        name: arrDepPackage.description.trim(),
        itemCategory: 'Arr/Dep Package',
        itemCategory2: arrDepPackage.packageType,
        price: passenger.price,
        bookingCode: arrDepPackage.packageCode,
        quantity: 1
      }) as Analytics.Events.TExtraItem
  );

/* Used by purchase events below */
const returnExcursionEvents = (excursions: PG.Response.Shared.TExcursion[]) =>
  excursions.reduce((acc: Analytics.Events.TExtraItem[], excursion) => {
    const excursionArray = excursion.passengers.map(
      (passenger) =>
        buildEcommerceItem({
          id: excursion.productCode,
          name: excursion.description.trim(),
          itemCategory: 'Excursion',
          price: passenger.price,
          bookingCode: excursion.productCode,
          quantity: 1
        }) as Analytics.Events.TExtraItem
    );
    return acc.concat(mergeItemsExceptBy(excursionArray, 'price'));
  }, []);

/* This event occurs on the confirmation page after successful payment.
This event is still needed as a backup event store (just in case we didn't
record the same event before payment). However, the length of time the page
loads causes some users to navigate away */
export const returnPurchaseEvent = (
  booking: PG.Response.Booking.TRootObject,
  voyage: TVoyageBookingFlowInformation
) => {
  const passengerGroup = getPassengerGroup(booking);
  const packageData = getPackageFromBooking(passengerGroup);
  const voyageDetails = getVoyageDetailsFromBooking(passengerGroup);
  const cabins = voyageDetails?.cabins;

  const duration = getDateDifferenceInDays(
    createDateAsUTC(booking.departureDate),
    createDateAsUTC(booking.endDate)
  );

  if (
    !packageData ||
    !voyageDetails ||
    !booking.passengerGroups[0] ||
    !cabins
  ) {
    throw Error('Invalid booking');
  }

  let cabinEvents: Analytics.Events.TVoyageItem[] = [];
  let excursionEvents: Analytics.Events.TExtraItem[] = [];
  let flightEvents: Analytics.Events.TExtraItem[] = [];

  /* Potentially multiple cabins with multiple different value passengers */
  if (cabins) {
    cabinEvents = cabins?.reduce(
      (acc: Analytics.Events.TVoyageItem[], cabin) => {
        const eventArray = returnCabins({
          cabin,
          voyage,
          duration,
          packageData,
          voyageDetails
        });
        return acc.concat(eventArray);
      },
      []
    );
  }

  const arrDepPackages = getArrivalDepaturePackages(passengerGroup);
  if (arrDepPackages) {
    flightEvents = arrDepPackages.reduce(
      (acc: Analytics.Events.TExtraItem[], arrDepPackage) => {
        const flightArray = returnBookingAndQuoteEvents(
          arrDepPackage.passengers,
          arrDepPackage
        );
        return acc.concat(mergeItemsExceptBy(flightArray, 'price'));
      },
      []
    );
  }

  if (voyageDetails.excursions) {
    excursionEvents = returnExcursionEvents(voyageDetails.excursions);
  }

  const items: Array<
    Analytics.Events.TExtraItem | Analytics.Events.TVoyageItem
  > = [...cabinEvents, ...flightEvents, ...excursionEvents];

  return {
    totalPrice: booking.totalPrice,
    bookingId: booking.bookingId,
    items
  };
};

/* This with the the quote payment event (below) gives us greater
tracking of payment events which happen outside the app */
export const returnPaymentIds = (paymentUrl: string) => {
  const params = paymentUrl.split('?')[1];
  const urlBlocks = params
    .split('&')
    .reduce((acc: Record<string, string>, current) => {
      const [key, value] = current.split('=');
      if (key && value) {
        acc[key] = value;
      }
      return acc;
    }, {});
  return {
    correlationId: urlBlocks?.correlationId ?? '',
    paymentId: urlBlocks?.paymentId ?? ''
  };
};

/* This event gets returned just before sending to payment,
hence why we are using the quote. The data should be exactly
the same as the purchase event above. The reason we are doing this
is that tracking purchase events will give us more visibility in
GA as not everyone who purchases returns to the confirmation page */
export const returnQuotePurchaseEvent = (
  quote: PG.Response.Quote.TRootObject,
  voyage: TVoyageBookingFlowInformation
) => {
  const packageData = getPackage(quote);
  const voyageDetails = getVoyageDetails(quote);
  const cabins = getCabins(quote);

  const duration = getDateDifferenceInDays(
    createDateAsUTC(voyageDetails?.departureDateTime),
    createDateAsUTC(voyageDetails?.arrivalDateTime)
  );

  if (!packageData || !voyageDetails || !cabins) {
    throw Error('Invalid quote');
  }

  let cabinEvents: Analytics.Events.TVoyageItem[] = [];
  let excursionEvents: Analytics.Events.TExtraItem[] = [];
  let flightEvents: Analytics.Events.TExtraItem[] = [];

  /* Potentially multiple cabins with multiple different value passengers */
  if (cabins) {
    cabinEvents = cabins?.reduce(
      (acc: Analytics.Events.TVoyageItem[], cabin) => {
        const eventArray = returnCabins({
          cabin,
          voyage,
          duration,
          packageData,
          voyageDetails
        });
        return acc.concat(eventArray);
      },
      []
    );
  }

  const arrDepPackages = getArrivalDeparturePackages(quote);
  if (arrDepPackages) {
    flightEvents = arrDepPackages.reduce(
      (acc: Analytics.Events.TExtraItem[], arrDepPackage) => {
        const flightArray = returnBookingAndQuoteEvents(
          arrDepPackage.passengers,
          arrDepPackage
        );
        return acc.concat(mergeItemsExceptBy(flightArray, 'price'));
      },
      []
    );
  }

  const excursions = getExcursions(quote);
  if (excursions) {
    excursionEvents = returnExcursionEvents(excursions);
  }

  const items: Array<
    Analytics.Events.TExtraItem | Analytics.Events.TVoyageItem
  > = [...cabinEvents, ...flightEvents, ...excursionEvents];

  return {
    totalPrice: quote.price,
    bookingId: quote.quoteId,
    items
  };
};

export const returnVoyageAndCabinEvent = (
  cabinIndex: number,
  cabinInfo: ViewModel.Cabin.TRootObject | null | undefined,
  voyage: TVoyageBookingFlowInformation | null | undefined,
  quote: PG.Response.Quote.TRootObject | null | undefined
) => {
  /* 
  At this stage in the booking funnel (choosing a cabin) we can't tell
  directly from the updated quote what cabins have been just added as there
  may be multiple cabins already selected OR we may have just chosen a
  new cabin after already picking one.

  Therefore we get the cabin data using a combination of the selected cabinInfo
  */
  if (!quote || !voyage || !cabinInfo) {
    throw Error('Invalid quote');
  }

  const cabinData = getCabinFromQuote(cabinIndex, quote, cabinInfo);
  const packageData = getPackage(quote);
  const duration = getDuration(quote);
  const voyageDetails = getVoyageDetails(quote);

  if (!cabinData?.passengers || !packageData || !duration || !voyageDetails) {
    throw Error('Unable to get quote information');
  }

  const cabin: PG.Response.Shared.TCabin = cabinData;

  const items = returnCabins({
    cabin,
    voyage,
    duration,
    packageData,
    voyageDetails
  });

  return {
    totalPrice: cabinData?.includedPrice,
    items
  };
};
