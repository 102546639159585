export const BookingStatus = {
  Reserved: 'Reserved',
  Unconfirmed: 'Unconfirmed',
  Confirmed: 'Confirmed',
  Cancelled: 'Cancelled'
};

export const PackageType = {
  Full: 'FULL',
  Arrival: 'ARRIVAL',
  Departure: 'DEPARTURE',
  Post: 'POST',
  Pre: 'PRE'
};
